.header-action-view {
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 0px;
    min-height: 50px;
}

.listdata-img {
    width: 40%;
    max-width: 500px;
}

.scroll-up {
    min-height: 50px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .listdata-img {
        width: 100%;
    }

    .header-action-view {
        padding-top: 7px;
    }

    .flex-center-row-xs {
        display: flex;
        justify-content: center !important;
        align-items: center;
        flex-direction: row !important;
    }

    .flex-start-row-xs {
        display: flex;
        justify-content: flex-start !important;
        align-items: center;
        flex-direction: row !important;
    }

    .flex-center-col-xs {
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        flex-direction: column !important;
    }

    .text-align-center-xs {
        text-align: center;
    }

    .scroll-up {
        z-index: 1;
        height: 87px;
        transform-origin: top;
        min-height: 0;
        transition: transform 0.3s ease-in-out, height 0.2s ease-in-out;
    }

    .scroll-up-hidden-xs {
        height: 0 !important;
        transform: translateY(-150px);
    }

    .header-action-view-payment {
        padding-top: 0 !important;
    }

    .mobile-hidden {
        display: none;
    }
}

@media screen and (min-width: 601px) {
    .desktop-hidden {
        display: none;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 768px) {
    .flex-center-row-sm {
        display: flex;
        justify-content: center !important;
        align-items: center;
        flex-direction: row !important;
    }

    .header-size-logo {
        width: 50px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
