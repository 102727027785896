:root {
  --node-border-radius: 12px;
  --node-padding: 15px;
  --node-color: #555555;
}

//Custom react flow style
.react-flow__edge-path {
  stroke: #b1b1b7 !important;
  stroke-width: 3px !important;
}

.react-flow__edge.selected .react-flow__edge-path {
  stroke: var(--infoColor) !important;
}

.node {
  word-wrap: break-word;
  padding-left: 10px;
  padding-right: 10px;
}

.node-action {
  visibility: hidden;
  opacity: 0;
  transition: all 200ms ease-in-out;
  transform: scale(0);
  transform-origin: bottom right;
}

.node:hover > .node-action, .node-action.selected {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.node-container {
  position: relative;
  border: 2px solid var(--primaryColor);
  border-radius: var(--node-border-radius);
  background: #fff;
  transition: all 200ms ease-in-out;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
  rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.node-selected {
  border: 2px solid var(--infoColor);
}

.node-disable {
  filter: grayscale(100%);
}

.mini-map {
  border: 1px solid #eeeeee;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
  rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.node-container:hover,
.mini-map:hover {
  z-index: 1000;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
  rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
  rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.node-title {
  color: #fff;
  background: var(--primaryColor);
  border-top-right-radius: calc(var(--node-border-radius) - 2.5px);
  border-top-left-radius: calc(var(--node-border-radius) - 2.5px);
  padding: var(--node-padding);
  position: relative;
}

.node-trigger-config {
  margin-top: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #ffffff;
  font-size: 13px;
  color: var(--node-color);
}

.node-trigger-config i {
  margin-right: 5px;
  color: var(--successColor);
  font-size: 15px;
}

.node-scenario-content {
  width: 216px;
  background: white;
  position: relative;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  margin: var(--node-padding);
  color: var(--node-color);

  &:hover {
    z-index: 10000 !important;
  }
}

.node-scenario-button {
  padding: 5px;
  border: 1px solid #0077ff;
  border-radius: 4px;
  background-color: #ffffff;
  background-size: cover;
  color: #0077ff;
  text-align: center;
  margin-top: 7px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.node-scenario-image {
  max-width: 100%;
  object-fit: cover;
  max-height: 300px;
  width: 216px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  pointer-events: none;
}

.handle {
  position: absolute;
  z-index: 300;
}

.group-actions {
  max-width: 300px;
  @apply flex-col;

  .action {
    cursor: pointer;
    width: 80px;
    height: 80px;
    border: 2px dashed #d9d9d9;
    border-radius: 10px;
    transition: all 250ms ease-in-out;
    color: rgba(0, 0, 0, 0.8);
    padding: 5px;
    text-align: center;

    &:hover {
      border: 2px dashed var(--infoColor);
      color: var(--infoColor);

      .icon {
        color: var(--infoColor);
      }

      .label {
        color: var(--infoColor);
        @apply font-bold;
      }
    }

    .icon {
      font-size: 25px;
      width: 30px;
      height: 30px;
    }

    .label {
      font-size: 12px;
    }

    .pro-icon {
      @apply absolute ml-2 transform scale-75;
      top: -8px;
      right: -10px;
    }
  }
}

.action {
  &:hover {
    .setting {
      visibility: visible;
      opacity: 1;
      transform: scale(1);
    }
  }

  .setting {
    opacity: 0;
    transition: all 300ms ease-in-out;
    transform: scale(0);
    transform-origin: top left;
  }
}

.react-flow__handle {
  width: 30px !important;
  height: 30px !important;
  z-index: 1;

  &-left {
    top: 12px !important;
    left: -17px !important;
    background: var(--successColor) !important;

    &::after {
      @apply justify-center items-center absolute rounded-full text-white;
      transform: rotate(-90deg);
      display: flex;
      font-family: 'Font Awesome 5 Pro', serif;
      content: "\f076";
      width: 32px;
      height: 28px;
      font-weight: bold;
    }
  }

  &-right {
    position: relative !important;
    //top: 17px !important;
    //left: 0 !important;
    background: var(--grayColor) !important;

    &::after {
      transform: rotate(90deg);
      @apply justify-center items-center absolute rounded-full text-white;
      display: flex;
      font-family: 'Font Awesome 5 Pro', serif;
      content: '\f076';
      width: 24px;
      height: 28px;
      font-weight: bold;
    }

    &.button {
      //top: 15px !important;
      //right: -42px !important;
      //left: unset !important;
      background: var(--dangerColor) !important;
    }

    &.url {
      //top: 15px !important;
      //right: -42px !important;
      //left: unset !important;
      background: var(--dangerColor) !important;

      &::after {
        transform: rotate(90deg);
        @apply justify-center items-center absolute rounded-full text-white;
        display: flex;
        font-family: FontAwesome, serif;
        content: '\f0c1';
        width: 28px;
        height: 28px;
      }
    }
  }
}

.handle-container {
  position: absolute;
  justify-content: space-between;
  display: flex;
  right: -90px;
  top: -10px;
  width: 90px;
  height: 50px;
  z-index: 20;

  &.inSlider {
    right: -70px;
    @apply shadow
  }

  &.button {

    &.inSlider {
      right: -80px;
    }

    right: -100px;
  }


  .add-button {
    visibility: hidden;
    transition: 200ms all ease-in-out;
    transform: scale(0);
  }

  &:hover .add-button {
    visibility: visible;
    transform: scale(1);
    opacity: 0.8;
  }
}

.input-content {
  position: relative;

  .action {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 0px 7px 0px 3px;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    right: 0;
    height: 22px;
    bottom: -16px;
    transition: all 200ms ease-in-out;
    opacity: 0;
    visibility: hidden;
  }

  &:hover {
    .action {
      opacity: 1;
      visibility: visible;
    }
  }
}

div#wave {
  position:relative;
  text-align:center;
  height: 70px;
  margin-left: auto;
  margin-right: auto;
  .dot {
    display:inline-block;
    width:12px;
    height:12px;
    border-radius:50%;
    margin-right:3px;
    background: rgba(51, 51, 51, 0.7);
    animation: wave 1.3s ease-in-out infinite;

    &:nth-child(2) {
      animation-delay: -1.1s;
    }

    &:nth-child(3) {
      animation-delay: -0.9s;
    }
  }
}

@keyframes wave {
  0%, 60%, 100% {
    transform: initial;
  }

  30% {
    transform: translateY(-10px);
  }
}
