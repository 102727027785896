.editor {
  .mention {
    color: var(--infoColor);
    border-radius: 5px;
    font-weight: bold;
  }

  .mentionSuggestions {
    font-weight: normal !important;
    border: 1px solid #eee;
    border-radius: 5px;
    position: absolute;
    min-width: 220px;
    max-width: 440px;
    background: #fff;
    box-shadow: 0 4px 30px 0 rgba(220, 220, 220, 1);
    cursor: pointer;
    padding-top: 8px;
    padding-bottom: 8px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    transform: scale(0);
  }

  .mentionSuggestionsEntry {
    padding: 7px 10px 3px 10px;
    transition: background-color 0.4s cubic-bezier(.27, 1.27, .48, .56);
  }

  .mentionSuggestionsEntry:active {
    background-color: #EEA2367F;
  }

  .mentionSuggestionsEntryFocused {
    padding: 7px 10px 3px 10px;
    transition: background-color 0.4s cubic-bezier(.27, 1.27, .48, .56);
    background-color: #EEA2367F;
  }

  .mentionSuggestionsEntryFocused:active {
    background-color: #EEA2367F;
  }

}
