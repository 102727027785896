.time-condition {
  .MuiFormControlLabel-label {
    width: 100% !important;
  }
  
  .radio-time-cycle {
    .MuiFormControlLabel-root {
      display: flex !important;
      flex-direction: row !important;
      align-items: flex-start !important;
    }

    .PrivateSwitchBase-root-272 {
      padding-top: 5px !important;
    }
  }
}