.cron-select-period {
    padding: 5px 5px 0px 5px;
}

.cron-input {
    padding: 5px 5px 0px 5px;
}

.cron-select-period select,
.cron-input select,
.cron-input input[type=radio], 
.cron-input input[type=checkbox] {
    margin-left: 5px;
    margin-right: 5px;
}

select {
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

select:hover {
    transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    border-bottom: 2px solid #ff9800;
}