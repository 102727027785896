.Order-column {
    border-right: 1px solid #eeeeee;
    padding-right: 25px;
}

.Order-item-text {
    height: 43px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    font-size: 14px;
}

.Order-item-image {
    border-radius: 15px;
    width: 80px;
    height: 80px;
    object-fit: cover;
}
