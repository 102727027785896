.CardItem-text {
    max-height: 20px;
    min-height: 17px;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    font-size: 0.9rem;
    white-space: nowrap;
}

.CardItem-address {
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
    font-size: 0.9rem;
}
