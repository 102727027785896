.history-total-title {
    font-size: 12px;
    color: #666666;
    text-decoration: none solid rgb(102, 102, 102);
    line-height: 32px;
}

.history-total-value {
    padding: 0 10px;
    background-color: #ffffff;
    background-size: cover;
    font-size: 20px;
    color: #555555;
    text-decoration: none solid rgb(85, 85, 85);
}

.history-timeline-date {
    font-size: 11px;
    color: #b1b1b1;
    text-decoration: none solid rgb(177, 177, 177);
    line-height: 32px;
}

.history-timeline-total {
    font-size: 16px;
    color: #666666;
    text-decoration: none solid rgb(102, 102, 102);
    line-height: 32px;
    margin-bottom: 10px;
}

.history-timeline-seller-1 {
    width: 159px;
    height: 32px;
    font-size: 11px;
    color: #b1b1b1;
    text-decoration: none solid rgb(177, 177, 177);
    line-height: 32px;
    text-align: right;
}

.history-timeline-seller-2 {
    width: 159px;
    height: 32px;
    font-size: 11px;
    color: #0084ff;
    text-decoration: underline solid rgb(0, 132, 255);
    line-height: 32px;
    text-align: right;
}
