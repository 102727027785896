.receipt-container {
    width: auto;
    border: 2px #000 solid;
    margin: 7px;
}

.receipt-border-bottom {
    border-bottom: 1px #4a4a4a40 solid;
}

.receipt-border-right {
    border-right: 1px #4a4a4a40 solid;
}

.receipt-logo {
    width: 100px;
    height: 80px;
    padding: 10px 0;
    object-fit: contain;
}

.receipt-title {
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 8px;
}

.receipt-date {
    font-size: 0.85rem;
}

.receipt-padding {
    padding: 5px;
}

.receipt-picking-slip-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    padding: 20px;
}

.receipt-product {
    border-bottom: 1px dotted;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 4px;
}

@media screen {
    .noPrint {
    }

    .no-display {
        display: none;
    }
}

@media print {
    .noPrint {
        display: none;
    }

    .no-display {
    }
}
