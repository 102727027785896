.router-enter {
    opacity: 0;
    transform: scale(0.9);
}

.router-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.router-exit {
    opacity: 1;
}

.router-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}

.fade1-enter {
    opacity: 0;
    z-index: 1;
}

.fade1-enter.fade1-enter-active {
    opacity: 1;
    transition: opacity 250ms ease-in;
}
