.template-content {
  .MuiInput-underline:before {
    border-bottom: 0 !important;
  }
  .MuiInput-underline:after {
    border-bottom: 0 !important;
  }

  .input-content-footer {
    font-size: 13px;
    z-index: 1000;
    position: absolute;
    height: 20px;
    bottom: -21px;
    right: -2px;
    padding: 0 3px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    opacity: 0;
    transition: all 250ms ease-in-out;
  }

  .input-content:hover .input-content-footer {
    opacity: 1;
  }


}

.light {
  .template-background {
    background-color: white !important;
  }

  .input-content-footer {
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
  }

}

.dark {
  .template-background {
    background-color: #151517 !important;
  }

  .MuiDivider-root {
    background-color: #a8a8a8;
  }

  .title-input {
    .MuiInputBase-input {
      color: #F2F3F5;
    }
  }
  .content-input {
    .MuiInputBase-input {
      color: #BCBCBE;
    }
  }

  .input-content-footer {
    background-color:  #fff ;
    color:rgba(0, 0, 0, 0.7);
  }

}

