.TagProducts-item-image {
    border-radius: 6px;
    width: 80px;
    height: 100%;
    object-fit: cover;
}

.TagPro-column-1 {
    border-right: 1px solid #eeeeee;
    padding-right: 25px;
}

.TagProducts-item-text {
    max-height: 20px;
    min-height: 17px;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    font-size: 0.9rem;
}
