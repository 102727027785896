.import-modal-dropzone-container {
    width: 100%;
    min-height: 150px;
    border-radius: 10px;
    margin-bottom: 10px;
    border: 1px solid #d9d9d9;
    cursor: pointer;
}

.import-modal-dropzone-container:hover {
    border: 1px solid #2979ff;
}

.import-modal-icon {
    font-size: 48px;
    margin-bottom: 10px;
    color: lightgray;
}

.import-modal-helper-text {
    color: lightgray;
}

.import-modal-file-icon {
    margin-right: 10px;
    margin-top: 2px;
    font-size: 18px;
    color: lightgray;
}

.import-modal-file-name {
    font-size: 18px;
    color: #2979ff;
}
