@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --primaryColor: #eea236;
    --warningColor: #ff9800;
    --dangerColor: #f44336;
    --successColor: #4caf50;
    --infoColor: #2979ff;
    --roseColor: #e91e63;
    --grayColor: #999999;
    --behanceColor: #1769ff;
}

html {
    font-size: 14px;
}

body {
    background-color: #e9ebee;
    line-height: normal;
    overflow: hidden;
    height: 100%;
    font-family: "Open Sans", sans-serif;
}

body .waiting {
    cursor: progress !important;
}

button {
    outline: none !important;
    cursor: pointer;
}

textarea {
    overflow: hidden;
}

img {
    display: block;
}

.flex {
    position: relative;
    display: flex;
}

.white {
    background-color: #fff;
}

.flex_full_row {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: row;
}

.flex-center-col {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.flex-center-row {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-start-row {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.flex-space-between-row {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex-end-row {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.flex_center_col {
    position: relative;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-start-col {
    position: relative;
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-self: auto;
}

.flex_end_col {
    position: relative;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.flex-full {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
}

.flex_full_center {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
}

.Company-logo {
    width: 250px;
    margin-bottom: 10px;
}

.login-Facebook {
    padding: 10px 25px;
    border-radius: 30px;
    background-color: #4267b2;
    font-size: 1.1rem;
    font-weight: 300;
    color: #ffffff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: none;
    margin-top: 30px;
    font-family: "Open Sans", sans-serif;
}

.header {
    background-color: #fff;
    z-index: 20;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
    padding-bottom: 0;
}

.header-logo {
    background-color: #ec971f;
}

.header-name {
    color: #000;
    font-size: 0.9rem;
    padding: 5px 15px;
    border-radius: 20px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.header-trial {
    margin-left: 5px;
    margin-right: 5px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ef6c00;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.visible-hidden {
    visibility: hidden;
}

.text-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.break-word-overflow {
    overflow-wrap: break-word;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.description-text {
    max-width: 600px;
    width: 60%;
    font-size: 1rem;
    text-align: center;
}

.content-father {
    padding: 10px 20px;
}

.imgFluid {
    max-width: 100%;
    height: auto;
}

.imgRounded {
    border-radius: 6px !important;
}

.imgRoundedCircle {
    border-radius: 50% !important;
}

.imgRaised {
    box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.imgAvatar {
    box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.imgProductOrder {
    border-radius: 6px !important;
    max-width: 80%;
    max-height: 50px;
    height: auto;
}

.text {
    margin-bottom: 5px;
}

.imgSubcrip {
    height: auto;
    width: 120px;
}

.imgPadding {
    padding: 0 23px;
}

.text-name-subscription {
    color: #555555;
    text-decoration: none solid rgb(85, 85, 85);
    text-align: center;
    font-size: 1rem;
}

.text-mean-subscription {
    color: #555555;
    text-decoration: none solid rgb(85, 85, 85);
    text-align: center;
    height: 95px;
}

.text-coupon-flatform {
    color: #ff9800;
    margin-top: -5px;
}

.btn-update-fanpage {
    margin: 0.3125rem 10px 0.3125rem 1px;
    -webkit-appearance: button;
    border-radius: 3px;
    box-shadow: 0 2px 2px 0 rgba(0, 188, 212, 0.14),
    0 3px 1px -2px rgba(0, 188, 212, 0.2), 0 1px 5px 0 rgba(0, 188, 212, 0.12);
    background-color: #2979ff;
    color: #ffffff;
    cursor: pointer;
    border: none;
    padding: 8px 20px;
    position: relative;
    font-size: 0.8rem;
    min-width: auto;
    text-align: center;
    transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    min-height: auto;
    will-change: box-shadow, transform;
    line-height: 1.42857143;
    white-space: nowrap;
    font-weight: 500;
    touch-action: manipulation;
    text-transform: none;
    letter-spacing: 0;
    vertical-align: middle;
    box-sizing: border-box;
    display: inline-flex;
    user-select: none;
    align-items: center;
    justify-content: center;
    -webkit-tap-highlight-color: transparent;
    overflow: visible;
}

.btn-update-fanpage::selection {
    background-color: #cce2ff;
    color: rgba(0, 0, 0, 0.87);
    cursor: pointer;
    border-radius: 5px;
    -webkit-transition: color 0.2s ease;
    -o-transition: color 0.2s ease;
    transition: color 0.2s ease;
}

.not-round {
    border-radius: 3px;
}

/*todo payemnt*/
.payment-header {
    font-size: 1.1rem;
    font-weight: bold;
    text-align: center;
    padding: 5px;
}

.payment-item {
    padding: 5px;
    font-size: 1rem;
}

.money_confirm {
    font-size: 1.1rem;
    color: blue;
}

.bold {
    font-weight: 600;
}

.header-size-logo {
    width: 80px;
}

.footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 30px;
}

.Search-container {
    width: 100%;
    border-radius: 5px;
    box-sizing: border-box;
    cursor: default;
    font-weight: normal;
    position: relative;
    vertical-align: middle;
    background-color: #f5f6f7;
    display: flex;
    align-items: center;
    padding-left: 4px;
    padding-right: 4px;
    height: 35px;
    border: 1px solid #eeeeee;
}

.Search-container:hover {
    background-color: rgba(255, 255, 255, 0.25);
}

.Search-input {
    position: relative;
    border-style: none;
    padding: 0 2px;
    background: transparent;
    margin: 0;
    outline: 0;
    width: 100%;
    flex: 1;
    color: #000;
    font-size: 0.85rem;
}

.font30 {
    font-size: 1.5rem;
}

.font20 {
    width: 20px;
}

.font3025 {
    font-size: 1.5rem;
    width: 25px;
}

.font40 {
    font-size: 2rem;
}

.loading-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.picker-container {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 2px;
    border-radius: 10px;
}

.shipping-method {
    border: 1px solid #c2c2c2;
    padding-left: 10px;
    margin-top: 5px;
    border-radius: 4px;
}

.Search-label {
    margin-top: 4px;
    width: 35px;
    border-radius: 25px;
    box-sizing: border-box;
    cursor: default;
    font-weight: normal;
    position: relative;
    vertical-align: middle;
    background-color: #f5f6f7;
    display: flex;
    align-items: center;
    padding-left: 4px;
    padding-right: 4px;
    height: 35px;
    margin-right: 5px;
    border: 1px solid #eeeeee;
    transition: all 500ms ease-in-out;
}

.input-border {
    width: 50px;
    height: 25px;
    text-align: center;
    margin: 5px;
    outline: none;
    border: 1px solid #eeeeee;
    border-radius: 5px;
}

.hover-shadow {
    background-color: #fff;
    transition: background-color 300ms ease-in-out;
}

.hover-shadow:hover {
    background-color: rgba(0, 0, 0, 0.08);
}

.link,
.link:hover,
.link:visited,
.link:active {
    color: var(--infoColor) !important;
    text-decoration: underline;
}

.text-align-center {
    text-align: center;
}

.shadow {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.shadow1 {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.multiline-input {
    border: 2px #e0e6ed solid;
    padding: 5px 10px 6px;
    white-space: pre-wrap;
    margin-bottom: 10px;
    border-radius: 12px;
}

.overflowY {
    overflow-y: auto;
    overflow-x: hidden;
}

.overflow-auto {
    overflow: auto;
}

.text-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-start {
    text-align: start !important;
}

.text-center {
    text-align: center !important;
}

.text-end {
    text-align: end !important;
}

.text-lowercase {
    text-transform: lowercase;
}

{
    text-transform: uppercase
;
}

.text-italic {
    font-style: italic;
}

.text-bold {
    font-weight: 600;
}

.text-line-through {
    text-decoration: line-through;
}

.premium-icon {
    height: 62px;
    width: 62px;
}

.premium-title {
    font-family: Open Sans;
    font-size: 28px;
    color: #e09404;
    text-decoration: none solid rgb(224, 148, 4);
}

.premium-text {
    font-family: Open Sans;
    font-size: 20px;
    color: #e09404;
    text-decoration: none solid rgb(224, 148, 4);
    margin-bottom: 10px;
}

.premium-value {
    font-family: Open Sans;
    font-size: 22px;
    font-weight: bold;
    color: #e09404;
    text-decoration: none solid rgb(224, 148, 4);
    margin-bottom: 20px;
}

.width-100 {
    width: 100%;
}

.shake {
    display: block;
    position: relative;
    animation-name: spaceboots;
    animation-duration: 1.2s;
    transform-origin: 50% 50%;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

h2.shake,
.shake.inline {
    display: inline-block;
}

@-webkit-keyframes spaceboots {
    0% {
        transform: translate(2px, 1px) rotate(0deg);
    }
    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
        transform: translate(0px, 2px) rotate(0deg);
    }
    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
        transform: translate(2px, 1px) rotate(-1deg);
    }
    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
        transform: translate(2px, 2px) rotate(0deg);
    }
    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

.primaryColor {
    color: var(--primaryColor) !important;
}

.warningColor {
    color: var(--warningColor) !important;
}

.dangerColor {
    color: var(--dangerColor) !important;
}

.successColor {
    color: var(--successColor) !important;
}

.infoColor {
    color: var(--infoColor) !important;
}

.roseColor {
    color: var(--roseColor) !important;
}

.grayColor {
    color: var(--grayColor) !important;
}

.MuiAutocomplete-endAdornment {
    top: unset !important;
}

.white-space-nowrap {
    white-space: nowrap;
}

.visibility-hidden {
    visibility: hidden;
}

.font-smaller {
    font-size: smaller;
}

.font-larger {
    font-size: larger;
}

.primary-color {
    color: var(--primaryColor) !important;
}

.warning-color {
    color: var(--warningColor) !important;
}

.danger-color {
    color: var(--dangerColor) !important;
}

.success-color {
    color: var(--successColor) !important;
}

.info-color {
    color: var(--infoColor) !important;
}

.rose-color {
    color: var(--roseColor) !important;
}

.gray-color {
    color: var(--grayColor) !important;
}

.slick-slide {
    padding: 0 8px;
    box-sizing: border-box;
}

.add-carousel-card {
    display: flex !important;
    min-height: 315px !important;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    border: 2px #e0e6ed solid;
}

.only-button {
    display: none;
}

[role="button"] .only-button {
    display: block;
}

.print-only {
    display: none;
}

@media print {
    .no-print {
        display: none;
    }

    .print-only {
        display: block;
    }
}

.MuiCircularProgress-svg {
    margin: 0 !important;
}

.MuiPaper-rounded {
    border-radius: 12px !important;
}

.flash-effect{
    -webkit-animation: flash linear 1s infinite;
    animation: flash linear 1s infinite;
}
@-webkit-keyframes flash {
    0% { opacity: 1; }
    50% { opacity: .1; }
    100% { opacity: 1; }
}
@keyframes flash {
    0% { opacity: 1; }
    50% { opacity: .1; }
    100% { opacity: 1; }
}

.card-active {
    border: 2px solid var(--primaryColor) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.notification-menu-read {
		opacity: 0.5;
}
