.map-search-input {
  background: none padding-box rgb(255, 255, 255);
  border-radius: 2px;
  border: 1px solid transparent;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px -1px;
  box-sizing: border-box;
  font-family: Roboto, Arial, sans-serif;
  font-size: 18px;
  font-weight: 300;
  margin-top: 10px;
  outline: none;
  padding: 0 11px 0 13px;
  text-overflow: ellipsis;
  width: 300px;
  text-transform: none;
  appearance: none;
  position: relative;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  height: 40px;
  vertical-align: middle;
  color: rgb(86, 86, 86);
  min-width: 66px;
}

.pac-container {
  &.pac-logo {
    z-index: 2000;
  }
}
