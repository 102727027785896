.highlight-backdrop {
    position: absolute;
    z-index: 1;
    overflow: auto;
    pointer-events: none;
    transition: transform 1s;
}

.highlight-content {
    margin-top: 4px;
    white-space: pre-wrap;
    word-wrap: break-word;
    color: #000;
    line-height: 1.5;
}

.mark {
    border-radius: 3px;
    background-color: #2979ff;
    color: #fff;
}

.rule-content-footer {
    text-align: center;
    flex: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 35px;
    background-color: #faebcc;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    color: #a8a8a8;
}

.rule-index {
    height: 100%;
    position: absolute;
    vertical-align: middle;
    right: -100px;
    font-size: 50px;
    font-weight: bold;
    color: #000;
    opacity: 0.2;
    transition: all 200ms ease-in-out;
}

.message-setting {
    color: #000;
    opacity: 0;
    transition: all 200ms ease-in-out;
}

.message-setting-open {
    opacity: 1;
}

.message-keyword {
    overflow-x: scroll;
}

.rule-message {
    width: 100%;
    position: relative;
}

.rule-message:hover {
    z-index: 499;
}

.rule-message:hover .rule-index {
    opacity: 0.5;
}

.rule-message:hover .message-setting {
    opacity: 1;
    z-index: 11;
}

.message-left {
    flex-direction: row;
}

.message-right {
    flex-direction: row-reverse;
}

.message {
    padding: 5px 10px 6px;
    white-space: pre-wrap;
    margin-bottom: 10px;
    border-radius: 12px;
}

.message-header {
    width: 90%;
}

.message-left .message {
    color: #000;
    background-color: #f1f0f0;
}

.message-right .message {
    color: #fff;
    background-color: #3578e5;
}

/*Card rule*/
.rule-card-container {
    width: 100%;
    /*max-width : 250px;*/
}

.rule-card-image {
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    height: 200px;
    overflow: hidden;
}

.rule-border-bottom {
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
}

.rule-card-text {
    position: relative;
    color: #b8bec5;
    transition: all 250ms ease-in-out;
}

.rule-card-text:hover {
    color: #000;
}

.rule-card-button {
    overflow: hidden;
}

.no-border-bottom {
    border-bottom-width: 0;
}

.border-bottom-button-rule {
    border-bottom-width: 2px;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
}

.border-rule {
    color: #e0e6ed;
    border: 2px #e0e6ed solid;
    transition: all 250ms ease-in-out;
}

.border-rule:hover {
    border: 2px var(--infoColor) solid;
    color: var(--infoColor);
}

.rule-footer-container {
    cursor: pointer;
    width: 90px;
    height: 90px;
    border: 2px dashed #d9d9d9;
    border-radius: 15px;
    transition: all 250ms ease-in-out;
    color: rgba(119, 119, 119, 0.8);
    padding: 5px;
}

.rule-footer-container:hover {
    border: 2px dashed var(--infoColor);
    color: var(--infoColor);
}

.rule-footer-content {
    text-align: center;
    font-size: 13px;
}

.rule-footer-icon {
    margin-bottom: 10px;
    font-size: 25px;
}

.rule-card-text-footer {
    font-size: 13px;
    z-index: 1000;
    position: absolute;
    height: 20px;
    bottom: -21px;
    right: -2px;
    padding: 0 3px;
    background-color: rgba(0, 0, 0, 0.7);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    color: #fff;
    opacity: 0;
    transition: all 250ms ease-in-out;
}

.rule-card-text:hover .rule-card-text-footer {
    opacity: 1;
}
