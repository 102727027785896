.advanced-switch {
  width: 100%;
  background-color: transparent !important;
  .MuiAccordionSummary-root {
    padding: 0 !important;
    background-color: transparent !important;
  }
  &.MuiAccordion-root:before {
    display: none;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 0px;
  }
  & > .MuiButtonBase-root {
    & > .MuiAccordionSummary-content {
      border-bottom: 1px solid;
      position: relative;
      margin: 0;
      & > .MuiTypography-root {
        background-color: #fff;
        position: relative;
        top: 10px;
        padding: 0 10px;

        .icon {
          font-size: 1.3rem;
        }
      }
    }
  }
}
