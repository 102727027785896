.estimated-pickup-time-container {
    background-color: #f0f0f0;
    padding: 5px 8px 5px 8px;
    font-size: 0.85rem;
}

.card-container {
    overflow: fragments;
    border-radius: 30px;
    height: 123px;
}
